body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  scroll-behavior: smooth;
}
* {
  box-sizing: border-box;
}
h1,
h2,
h3,
h4 {
  color: #333333;
  font-family: Montserrat, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}
p {
  color: #777777;
  font-family: "Roboto Slab","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 16px;
  line-height: 1.75;
}
section {
  padding: 2rem 6rem;
}
section:nth-child(odd):not(:last-of-type) {
  background-color: #f7f7f7;
}
blockquote {
  margin: 0;
}
@media screen and (max-width: 767px) {
  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 16px;
  }

  p {
    font-size: 14px;
  }

  section {
    padding: 2rem;
  }
}
